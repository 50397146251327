import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import PageInfo from "../components/PageInfo";
import { Advantages } from "../components/Advantages";
import { List } from "../components/List";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ContactForm from "../components/contact";

const pageData = {
  header: "Podpis kwalifikowany",
  mainTitle: "Wybierz swój",
  subtitle: "podpis elektroniczny CenCert",
  paragraph:
    "Podpis CenCert to łatwe i szybkie narzędzie do elektronicznego podpisywania ",
  box: (
    <>
      <div className="qual-sign-box-element">
        <h4>Podpis CenCert</h4>
        <span>ważny przez</span>
        <h3>1 rok</h3>
        <p>259,00 zł / netto</p>
      </div>
      <div className="qual-sign-box-element">
        <h4>Podpis CenCert</h4>
        <span>ważny przez</span>
        <h3>2 lata</h3>
        <p>309,00 zł / netto</p>
      </div>
      <div className="qual-sign-box-element">
        <h4>Podpis CenCert</h4>
        <span>ważny przez</span>
        <h3>3 lata</h3>
        <p>409,00 zł / netto</p>
      </div>
      <div className="qual-sign-box-element">
        <h4>Podpis CenCert</h4>
        <span>ważny przez</span>
        <h3>4 lata</h3>
        <p>569,00 zł / netto</p>
      </div>
      <div className="qual-sign-box-element">
        <h4>Podpis CenCert</h4>
        <span>ważny przez</span>
        <h3>5 lat</h3>
        <p>767,00 zł / netto</p>
      </div>
    </>
  ),
};

const cenCert = {
  header: "Podpis kwalifikowany",
  mainTitle: "Odnowienie",
  subtitle: "podpisu elektronicznego",
  paragraph:
    "Dzięki odnowieniu cetyfikatu na kolejny okres możesz bezpiecznie potwierdzać swoją tożsamość",
  box: (
    <>
      <div className="qual-sign-box-element">
        <h4>Podpis CenCert</h4>
        <span>odnowienie na</span>
        <h3>1 rok</h3>
        <p>219,00 zł / netto</p>
      </div>
      <div className="qual-sign-box-element">
        <h4>Podpis CenCert</h4>
        <span>odnowienie</span>
        <h3>2 lata</h3>
        <p>269,00 zł / netto</p>
      </div>
      <div className="qual-sign-box-element">
        <h4>Podpis CenCert</h4>
        <span>odnowienie na</span>
        <h3>3 lata</h3>
        <p>369,00 zł / netto</p>
      </div>
      <div className="qual-sign-box-element">
        <h4>Podpis CenCert</h4>
        <span>odnowienie</span>
        <h3>4 lata</h3>
        <p>529,00 zł / netto</p>
      </div>
      <div className="qual-sign-box-element">
        <h4>Podpis CenCert</h4>
        <span>odnowienie na</span>
        <h3>5 lat</h3>
        <p>729,00 zł / netto</p>
      </div>
    </>
  ),
};

const p1 = [
  {
    txt: (
      <p>
        <br />
        Wystawienie kwalifikowanego certyfikatu wymaga spotkania z Inspektorem
        ds. rejestracji, w jednym z naszych Punktów rejestracji. Możliwe jest
        również zamówienie usługi z dojazdem inspektora. Po okazaniu dokumentu
        tożsamości i podpisaniu niezbędnych dokumentów Inspektor wydaje kartę
        (lub token) z e-podpisem. Cała procedura typowo trwa ok. 15 minut.{" "}
        <br />
        Otrzymaną kartę trzeba następnie aktywować na swoim komputerze, przy
        użyciu pliku aktywacyjnego otrzymanego mailem z CenCert. Przed wizytą w
        wybranym punkcie rejestracji prosimy o telefoniczne potwierdzenie
        możliwości obsługi w danym terminie. Wyjątkiem jest Centralny Punkt
        Rejestracji, gdzie nie ma potrzeby telefonicznego uzgadniania wizyty (w
        godzinach pracy).
      </p>
    ),
  },
  {
    // pImg: <StaticImage className="desktopImg" src="../assets/images/bg2.jpg" />,
  },
];
const p2 = [
  {
    txt: (
      <p>
        <br />
        Ceny są określone przy opisie poszczególnych produktów. <br /> Robiąc
        zakupy u nas nie ponoszą Państwo żadnych dodatkowych kosztów typu
        „opłata za weryfikację tożsamości”, „opłata za przesłanie dokumentów”
        itp. Jedyne dodatkowe koszty, które mogą ewentualnie wystąpić, są
        związane z dodatkowymi usługami: dojazd pod wskazany przez Państwo
        adres, szkolenie itp.
        <br />
      </p>
    ),
  },
];
const p3 = [
  {
    txt: (
      <p>
        <br />
        Kwalifikowany certyfikat CenCert zawierający imię i nazwisko musi
        zawierać dane pozwalające na jednoznaczną identyfikację osoby – numer
        PESEL albo numer NIP (osoby fizycznej, użytkownika certyfikatu), albo
        numer dokumentu tożsamości. <br /> Wiele systemów administracji wymaga,
        aby w certyfikacie był umieszczony nr PESEL. <br /> Zakupu certyfikatu z
        nr dokumentu zamiast numeru PESEL należy rozważyć w przypadku, gdy
        będzie on wykorzystywany do podpisywania dokumentów dostępnych następnie
        publicznie lub w szerokim gronie odbiorców (np. do podpisywania aktów
        prawnych bądź decyzji administracyjnych). <br /> W przypadku
        certyfikatów nabywanych w celu kontaktu z systemami administracji (np.
        rozliczenia z urzędem skarbowym, eKRS), bezpieczniej jest kupić
        certyfikat z nr PESEL. <br />
        Wybór formatu certyfikatu (z nr PESEL czy bez) leży po stronie
        użytkownika i nie może być powodem roszczeń reklamacyjnych.
        <br />
      </p>
    ),
  },
];
const p4 = [
  {
    txt: (
      <p>
        <br />
        Certyfikaty anonimowe są wystawiane tak, jak wszystkie inne certyfikaty
        kwalifikowane (wymagają uwierzytelnienia na podstawie dokumentu
        tożsamości) i tak samo, jak inne certyfikaty kwalifikowane do podpisu –
        są przypisane wyłącznie do osoby, której zostały wystawione. <br /> W
        sensie prawnym podpis kwalifikowany złożony przy użyciu certyfikatu
        anonimowego jest takim samym podpisem kwalifikowanym (zastępującym
        podpis własnoręczny), jak podpis złożony przy użyciu certyfikatu
        zawierającego dane osobowe. W praktyce jednak nie można gwarantować, że
        w każdym systemie informatycznym certyfikaty anonimowe będą dopuszczone
        do użytku. W konkretnych zastosowaniach podpisu kwalifikowanego mogą
        istnieć jakieś specyficzne wymagania nakazujące możliwość identyfikacji
        osoby na podstawie podpisu (wtedy certyfikaty anonimowe nie będą mogły
        być tam zastosowane). <br /> Certyfikaty anonimowe są “anonimowe” tylko
        w pewnym stopniu. Odbiorca podpisanego dokumentu nie otrzymuje wraz z
        dokumentem danych osobowych – i w tym sensie certyfikaty są rzeczywiście
        anonimowe. Jednak CenCert, jako kwalifikowany podmiot świadczący usługę
        zaufania, dysponuje pełnymi danymi osobowymi właściciela certyfikatu. W
        przypadkach nakazanych przepisami prawa dane osobowe właściciela
        certyfikatu będą udostępnione uprawnionym instytucjom (Policja,
        prokuratura itp.).
        <br />
      </p>
    ),
  },
];

const p5 = [
  {
    txt: (
      <p>
        <br />
        Tak, udostępniamy dla jednostek administracji ścieżkę pozwalającą na
        zakup na podstawie faktury płatnej przelewem w terminie 21 dni. <br />
        W skrócie: Należy złożyć normalne zamówienie w sklepie www, wybierając
        płatność “przelewem bankowym”, następnie prosimy o maila na adres
        biuro@cencert.pl, z prośbą o wystawienie faktury do zamówienia o danym
        numerze. Po wystawieniu faktury, można od razu odebrać podpis, kod do
        odnowienia lub inny produkt, a zapłacić w terminie wskazanym na
        fakturze.
        <br />
      </p>
    ),
  },
];

const advElements = [
  {
    contentTitle: "Szybkość",
    contentTxt:
      "Wystarczą trzy kliklnięcia, aby bezpiecznie podpisać każdy element",
    imgSrc: require("../assets/images/qualSignOffer/szybkosc.svg").default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Oszczędność",
    contentTxt:
      "Oszczędzasz czas oraz koszty związane z wydrukiem i wysyłką dokumentacji",
    imgSrc: require("../assets/images/qualSignOffer/oszczednosc.svg").default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Dostępność",
    contentTxt: "To Ty decydujesz, o której i gdzie podpisujesz dokumeny",
    imgSrc: require("../assets/images/qualSignOffer/dostepnosc.svg").default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Bezpieczeństwo",
    contentTxt: "Masz pewność, że podpis nie został podrobiony",
    imgSrc: require("../assets/images/qualSignOffer/bezpieczenstwo.svg")
      .default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Zgodność",
    contentTxt: "Ma formalną i pełną moc prawną jak podpis własnoręczny",
    imgSrc: require("../assets/images/qualSignOffer/zgodnosc.svg").default,
    alt: "Opis obrazka",
  },
];

const QualSignPage = () => {
  const [active, setActive] = useState("active");
  useEffect(() => {
    window.addEventListener("load", function () {
      return setActive("active");
    });
  }, []);
  return (
    <>
      <div className={`sign-page-info ${active}`}>
        <PageInfo
          header={pageData.header}
          mainTitle={pageData.mainTitle}
          // date={pageData.date}
          subtitle={pageData.subtitle}
        />
      </div>
      <Layout>
        <section className="sign-page">
          <div className="offer-element">
            <div className="advantages-container offer">
              <div id="info" className="anchor"></div>
              <div className="aboutUs-container">
                <div className="imgContainer">
                  <StaticImage src="../assets/images/qualSignOffer/czym_jest_podpis.jpg" />
                  <div className="square"></div>
                </div>

                <div className="aboutUs txt">
                  <div id="abUs" className="anchor"></div>
                  <div className="aboutUs txt-container">
                    <h2>
                      Czym jest podpis
                      <br /> <strong> elektroniczny CenCert?</strong>
                    </h2>
                  </div>
                  <p className="headerTxt">
                    Podpis kwalifikowany to rodzaj podpisu elektronicznego,
                    który posiada taką samą{" "}
                    <strong>moc prawną jak tradycyjny podpis odręczny.</strong>{" "}
                    Jest poświadczony specjalnym certyfikatem kwalifikowanym,
                    który umożliwia jednoznaczną weryfikację osoby, która
                    dokonała podpisu. Tylko osoba, do której przypisane są
                    zarówno podpis, jak i certyfikat kwalifikowany, może z niego
                    korzystać.
                  </p>
                  <p className="headerTxt">
                    <strong>
                      Podpisem kwalifikowanym możesz podpisać dowolny dokument.
                    </strong>
                  </p>
                  <p className="headerTxt">
                    W przeciwieństwie od profilu zaufanego, za pomocą podpisu
                    kwalifikowanego{" "}
                    <strong>możesz nie tylko załatwiać sprawy urzędowe,</strong>
                    ale też na przykład zawierać umowy na odległość,
                    uczestnictwo w aukcjach elektronicznych na platformach
                    przetargowych oraz wiele innych działań, które wymagają
                    legalnej autoryzacji w środowisku cyfrowym.
                  </p>
                </div>
              </div>
              <Advantages
                headerSubtitle={
                  <>
                    Dlaczego warto wybrać
                    <br /> <strong> podpis CenCert?</strong>
                  </>
                }
                headerTxt={
                  "Podpis elektroniczny to innowacyjne narzędzie, które umożliwia bezpieczne i efektywne zawieranie umów oraz autoryzację dokumentów w środowisku on-line."
                }
                advantageElements={advElements}
              />
              <div className="aboutUs-container list">
                <div className="aboutUs txt">
                  <div id="abUs" className="anchor"></div>
                  <div className="aboutUs txt-container ">
                    <h2>
                      Jak podpisać dokument <br />{" "}
                      <strong>podpisem elektronicznym?</strong>
                    </h2>
                  </div>
                  <p className="headerTxt">
                    Masz już podpis elektroniczny – umowa podpisana, a zestaw
                    zawierający kartę oraz czytnik otrzymałeś od naszego
                    specjalisty?
                  </p>
                  <p className="headerTxt">
                    <strong>Czas podpisać pierwszy dokument!</strong>
                  </p>
                  <p className="headerTxt">
                    Jak to zrobić? Oto poradnik krok po kroku:
                  </p>
                  <b>1. </b>
                  <p>
                    Pobierz dokument i zapisz go na dysku swojego komputera.
                  </p>
                  <b>2.</b>
                  <p>Uruchom aplikację dołączoną do Twojego zestawu.</p>
                  <b>3.</b>
                  <p>Otwórz dokument w aplikacji i podpisz go.</p>
                  <b>4.</b>
                  <p>
                    Zweryfikuj podpis w aplikacji mobilnej lub włóż kartę do
                    czytnika i wpisz kod PIN.
                  </p>
                  <b>5.</b>
                  <p>Pobierz podpisany dokument i wyślij do odbiorcy.</p>
                </div>
                <div className="imgContainer">
                  <StaticImage src="../assets/images/qualSignOffer/main_podpis.jpg" />
                  <div className="square"></div>
                </div>
              </div>
              <div className="aboutUs txt advList">
                <div className="aboutUs txt-container">
                  <h2>
                    Zyskaj więcej <br /> <strong>z podpisem CenCert</strong>
                  </h2>
                </div>
              </div>
              <div className="advantages-container-wrapper">
                <div className="advantages-container-wrapper-box column">
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="ołówek"
                      src={
                        require("../assets/images/qualSignOffer/sign4.svg")
                          .default
                      }
                    />
                    <div className="element-wrapper">
                      <h3>PODPIS EL. DO JEDZ</h3>
                      <p>
                        Jednolity Dokument Europejski Zamówienia (popularnie
                        nazywany JEDZ) to standardowy, ujednolicony formularz
                        dla zamówień publicznych, których wartość przekracza
                        progi unijne. Progi te są różne w zależności od
                        przedmiotu zamówienia oraz rodzaju zamawiającego.
                      </p>
                    </div>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="ołówek"
                      src={
                        require("../assets/images/qualSignOffer/sign3.svg")
                          .default
                      }
                    />
                    <div className="element-wrapper">
                      <h3>SPRAWOZDANIA FINANSOWE DO KRS</h3>
                      <p>
                        Wszystkie spółki zarejestrowane w KRS mają obowiązek
                        składania sprawozdania finansowego wyłącznie w formie
                        elektronicznej. Nieodzowny do tego jest podpis
                        kwalifikowany, aby sprawozdanie do KRS zostało złożone
                        we właściwy sposób.
                      </p>
                    </div>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="ołówek"
                      src={
                        require("../assets/images/qualSignOffer/sign5.svg")
                          .default
                      }
                    />
                    <div className="element-wrapper">
                      <h3>PRZETARGI I AUKCJE ELEKTRONICZNE</h3>
                      <p>
                        W celu rejestracji i identyfikacji wykonawców można
                        stosować kwalifikowany podpis elektroniczny.
                      </p>
                    </div>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="ołówek"
                      src={
                        require("../assets/images/qualSignOffer/sign8.svg")
                          .default
                      }
                    />
                    <div className="element-wrapper">
                      <h3>E-DEKLARACJE</h3>
                      <p>
                        Od 1 stycznia 2015 r. każdy podmiot zatrudniający więcej
                        niż pięciu pracowników jest zobligowany do składania
                        deklaracji PIT-11, CIT-8 oraz IFT-2 wyłącznie w formie
                        elektronicznej.
                      </p>
                    </div>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="ołówek"
                      src={
                        require("../assets/images/qualSignOffer/sign14.svg")
                          .default
                      }
                    />
                    <div className="element-wrapper">
                      <h3>JEDNOLITY PLIK KONTROLNY</h3>
                      <p>
                        Dane w postaci omawianego formularza mogą być
                        przekazywane do urzędu skarbowego on-line, ale muszą być
                        podpisane kwalifikowanym podpisem elektronicznym, który
                        zagwarantuje bezpieczeństwo plików i zabezpieczy przed
                        jakimikolwiek zmianami
                      </p>
                    </div>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="ołówek"
                      src={
                        require("../assets/images/qualSignOffer/sign9.svg")
                          .default
                      }
                    />
                    <div className="element-wrapper">
                      <h3>ZAŚWIADCZENIA LEKARSKIE</h3>
                      <p>
                        Wystawiane zaświadczenie lekarskie w formie dokumentu
                        elektronicznego można uwierzytelnić certyfikatem
                        kwalifikowanym.
                      </p>
                    </div>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="ołówek"
                      src={
                        require("../assets/images/qualSignOffer/sign2.svg")
                          .default
                      }
                    />
                    <div className="element-wrapper">
                      <h3>GIIF</h3>
                      <p>
                        Wystarczą trzy kliknięcia, aby bezpiecznie podpisać
                        każdy dokument Wystarczą trzy kliknięcia, aby
                        bezpiecznie podpisać każdy dokument
                      </p>
                    </div>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="ołówek"
                      src={
                        require("../assets/images/qualSignOffer/sign11.svg")
                          .default
                      }
                    />
                    <div className="element-wrapper">
                      <h3>DOKUMENTACJA MEDYCZNA</h3>
                      <p>
                        Najlepszym sposobem na zapewnienie integralności
                        e-dokumentu, a także identyfikacji osoby udzielającej
                        świadczenia zdrowotnego jest kwalifikowany podpis
                        elektroniczny.
                      </p>
                    </div>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="ołówek"
                      src={
                        require("../assets/images/qualSignOffer/sign1.svg")
                          .default
                      }
                    />
                    <div className="element-wrapper">
                      <h3>GEODEZJA</h3>
                      <p>
                        Dokumentacja powstająca w wyniku prac branży geodezyjnej
                        docelowo powinna być opatrzona kwalifikowanym podpisem
                        elektronicznym.
                      </p>
                    </div>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="ołówek"
                      src={
                        require("../assets/images/qualSignOffer/sign12.svg")
                          .default
                      }
                    />
                    <div className="element-wrapper">
                      <h3>BUDOWNICTWO</h3>
                      <p>
                        Dokumentacja powstająca w wyniku prac branży geodezyjnej
                        docelowo powinna być opatrzona kwalifikowanym podpisem
                        elektronicznym.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`sign-page-info ${active} qual-sign`}>
                <PageInfo
                  mainTitle={pageData.mainTitle}
                  // date={pageData.date}
                  paragraph={pageData.paragraph}
                  subtitle={pageData.subtitle}
                  box={pageData.box}
                />
                <PageInfo
                  mainTitle={cenCert.mainTitle}
                  paragraph={cenCert.paragraph}
                  // date={pageData.date}
                  subtitle={cenCert.subtitle}
                  box={cenCert.box}
                />
              </div>
              <div className="opinion">
                <div className="txt">
                  <div id="opinions" className="anchor" />
                  <div className="txt-container">
                    <h2>
                      Podpis elektroniczny, <br />{" "}
                      <strong>pytania i odpowiedzi</strong>
                    </h2>
                  </div>
                </div>
                <div className="opinion-list-wrapper">
                  <List
                    listHeader={"Zakup e-podpisu"}
                    header={
                      "Jak zakupić e-podpis? Ile czasu to trwa? Jak szybko może być aktywowany?"
                    }
                    paragraph={p1[0].txt}
                  />
                  <List
                    header="Ile to kosztuje? Czy ponoszę jakieś dodatkowe koszty, np. za weryfikację tożsamości?"
                    paragraph={p2[0].txt}
                  />
                  <List
                    header="Czy certyfikat musi zawierać mój PESEL?"
                    paragraph={p3[0].txt}
                  />
                  <List
                    header="Co to jest certyfikat anonimowy?"
                    paragraph={p4[0].txt}
                  />
                  <List
                    header="Reprezentuję jednostkę administracji. Czy mogę zapłacić przelewem, po realizacji usługi?"
                    paragraph={p5[0].txt}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="footer-container-wrapper-form">
          <div className="left-col">
            {" "}
            <StaticImage
              style={{ zIndex: "1" }}
              className="desktopImg"
              src="../assets/images/qualSignOffer/cencert.jpg"
            />
            <div className="form-txt">
              <h2>Dział Obsługi Klienta</h2>
              <p>ds. Kwalifikowanego Podpisu Elektronicznego</p>
              <br />
              <p>
                Tel: <b>+48 83 344 70 06</b>
              </p>
              <p>
                Kom: <b>+48 726 447 000</b>
              </p>
              <br />
              <p>
                e-mail: <b>e-podpis@jbmultimedia.pl</b>
              </p>
            </div>
          </div>

          <div className="footer-txt-container">
            <Link to="/kontakt">
              <h2 className="txtSpecial">
                {" "}
                Porozmawiajmy o <b>Twoich potrzebach</b>
              </h2>
            </Link>
            <span>
              Cieszymy się, że jesteś zainteresowany współpracą z nami. Chętnie
              odpowiemy na Twoje pytania, udzielimy informacji lub omówimy Twoje
              potrzeby biznesowe.
            </span>
            <ContactForm />
          </div>
        </div>
      </Layout>
    </>
  );
};

export const Head = () => <Seo title="Oferta usługi podpisu elektronicznego" />;
export default QualSignPage;
